import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import tw, { theme, css } from 'twin.macro'
import Img from 'gatsby-image'
import Tilt from 'react-parallax-tilt'
import Grab from '../assets/grab.svg'

function ProjectPost(props) {
    return (
      <div
        tw="font-serif mb-10 md:mb-16 lg:mb-20 xl:mb-32 md:flex md:flex-row-reverse md:mx-auto md:place-items-stretch md:items-center"
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr min(1024px, calc(100% - 48px)) 1fr',
          maxWidth: '1024px',
          gridRowGap: 8,
          '& > *': {
            gridColumn: 2,
          },
          '@media (min-width: 768px)': {
            maxWidth: '1024px',
          },
        }}
      >
        <div
          tw="md:rounded mb-4 relative overflow-hidden md:mr-0 md:justify-self-end"
          css={{
            height: 'max(325px, 30vmin)',
            width: 'min(1024px, 100%)',
            margin: 'auto',
            gridColumn: '1 / -1',
            backgroundColor: `${theme`borderColor.accent`}`,
          }}
        >
          <div
            tw="relative top-1/5 sm:top-none sm:bottom-0 sm:left-0 sm:right-0 mx-auto sm:absolute"
            css={{
              width: 'min(1024px, calc(100% - 48px))',
              maxWidth: '275px',
            }}
          >
            <Tilt tiltReverse={true} tiltMaxAngleX="5.0" tiltMaxAngleY="5.0" gyroscope={true}>
              <Img
                tw="-mb-2"
                fluid={props.node.frontmatter.image.childImageSharp.fluid}
                alt={props.node.frontmatter.title}
                title={props.node.frontmatter.title}
              />
            </Tilt>
          </div>
          <div tw="bg-green-800 inline-block absolute px-2 py-1 font-sans text-xs font-medium rounded shadow-sm text-white top-4 left-4">
            Shipped
          </div>
        </div>

        <div
          tw="mt-4 md:mr-24"
          css={{
            width: '100%',
            '@media (min-width: 768px)': {
              width: 'min(1024px, calc(100% - 48px))',
            },
          }}
        >
          <h2>
            <Link
              tw="text-2xl lg:text-3xl hover:underline"
              css={{
                color: `${theme`colors.accent`}`,
                '&:hover': {
                  color: `${theme`colors.primary`}`,
                },
              }}
              to={props.node.fields.slug}
            >
              {props.node.frontmatter.title}
            </Link>
          </h2>
          <div tw="flex space-x-1.5 w-auto mt-1">
            {props.node.frontmatter.tags === 'Grab' ? (
              <>
                <div tw="flex">
                  <Grab css={{ width: 56, height: 32 }} />
                </div>
                <div tw="self-center mt-1 font-sans text-sm text-softer">·</div>
              </>
            ) : (
              ''
            )}
            <div tw="self-center mt-1 font-sans text-sm text-softer">
              Shipped in {format(new Date(props.node.frontmatter.date), 'MMM yyyy')}
            </div>
          </div>
          <p tw="mt-4 prose prose-lg ">{props.node.frontmatter.description}</p>
        </div>
      </div>
    )
}

export default ProjectPost