import React from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import tw, { theme, css } from 'twin.macro'
import Img from 'gatsby-image'
import Tilt from 'react-parallax-tilt'
import Grab from '../assets/grab.svg'

function FeaturedProject(props) {
    return (
      <div
        tw="font-serif mb-10 md:mb-16 lg:mb-20 xl:mb-32"
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr min(800px, calc(100% - 48px)) 1fr',
          gridRowGap: 8,
          '& > *': {
            gridColumn: 2,
          },
        }}
      >
        <div
          tw="md:rounded mb-4 relative overflow-hidden"
          css={{
            backgroundColor: `${theme`borderColor.accent`}`,
            height: ' max(560px, 40vmin)',
            width: 'min(1024px, 100%)',
            margin: 'auto',
            gridColumn: '1 / -1',
          }}
        >
          <div
            tw="absolute sm:top-24 bottom-0 left-0 right-0 mx-auto"
            css={{
              width: 'min(960px, calc(100% - 48px))',
            }}
          >
            <Tilt
              tiltReverse={true}
              tiltMaxAngleX="5.0"
              tiltMaxAngleY="5.0"
              gyroscope={true}
              glareMaxOpacity="0.5"
              glareReverse={true}
              glareEnable={true}
            >
              <Img
                tw="-mb-2"
                fluid={props.node.frontmatter.image.childImageSharp.fluid}
                alt={props.node.frontmatter.title}
                title={props.node.frontmatter.title}
              />
            </Tilt>
          </div>
          <div tw="bg-yellow-300 inline-block absolute px-2 py-1 font-sans text-xs font-medium rounded shadow-sm text-yellow-900 top-4 left-4">
            Work in Progress
          </div>
        </div>
        <div
          tw="md:mx-auto mt-4"
          css={{
            width: '100%',
            '@media (min-width: 768px)': {
              width: 'min(650px, calc(100% - 48px))',
            },
          }}
        >
          <h2>
            <Link
              tw="text-2xl lg:text-3xl hover:underline"
              css={{
                color: `${theme`colors.accent`}`,
                '&:hover': {
                  color: `${theme`colors.primary`}`,
                },
              }}
              to={props.node.fields.slug}
            >
              {props.node.frontmatter.title}
            </Link>
          </h2>
          <div tw="flex space-x-1.5 w-auto mt-1">
            {props.node.frontmatter.tags === 'Grab' ? (
              <>
                <div tw="flex">
                  <Grab css={{ width: 56, height: 32 }} />
                </div>
                <div tw="self-center mt-1 font-sans text-sm text-softer">·</div>
              </>
            ) : (
              ''
            )}
            <div tw="self-center mt-1 font-sans text-sm text-softer w-full">
              Work in progress since {format(new Date(props.node.frontmatter.date), 'MMM yyyy')}
            </div>
          </div>
          <p tw="mt-4 text-primary prose prose-lg xl:prose-xl">
            {props.node.frontmatter.description}
          </p>
        </div>
      </div>
    )
}

export default FeaturedProject